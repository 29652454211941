/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /gh/cutestat/bootstrap2@2.3.2/js/bootstrap.min.js
 * - /npm/raty-js@4.3.0/build/raty.min.js
 * - /npm/dayjs@1.11.13/dayjs.min.js
 * - /npm/dayjs@1.11.13/plugin/localizedFormat.min.js
 * - /npm/humanize-duration@3.32.1/humanize-duration.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
